import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';

import spotify from '../Assets/Alliances/spotify.png';
import deezer from '../Assets/Alliances/deezer.png';
import apple from '../Assets/Alliances/apple.png';
import amazon from '../Assets/Alliances/amazon.jpeg';
import youtube from '../Assets/Alliances/ytb.png';
import alliances from '../Assets/Alliances/alliances.jpg';

import Acheter from '../Assets/acheter.png';

import Artists from './Artists';
import Livret from './Livret';


export const Alliances = () => {

  const iframeCode = `
  <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/1NRJ9vJTOZ0e7HnMA3SOsM?utm_source=generator" width="100%" height="500" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  `;

  const iframeCodes = `
  <iframe style="border-radius:12px" src="https://open.spotify.com/embed/album/224FMZQz8wLB4CyESsuwSB?utm_source=generator" width="100%" height="500" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  `;

  return (
    <>


      <div className='music-info-container'>

        <h1> Rido Bayonne </h1>

        <Row >
          <Col sm={12} className='align-items-center img-alliances-container'>
            <img src={alliances} className="alliancesimg" />
          </Col>
          <Col sm={12} className='text-left info-alliances'>
            <h2 className='music-titre'>Alliances</h2>
            <p className='home-about-p start-music album-info'>
              Découvrez l'album de Rido Bayonne, intitulé Alliances. <br /> Ce double album compte 22 chansons, réparties en 11 titres par album.
            </p>
            <div className="button-container">
              <div className="bouton-rectangle-container-alliances">
                {/* <a href='https://buy.stripe.com/8wMcN39DB1K01lS000' target="_blank" className="bouton-rectangle-alliances-d">
                  ACHETER
                </a> */}
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input type="hidden" name="hosted_button_id" value="NAY9JGBKZ8EKJ" />
                  <input type="hidden" name="currency_code" value="EUR" />
                  <input type="image" src={Acheter} border="0" name="submit" title="PayPal, votre réflexe sécurité pour payer en ligne." alt="Acheter" />
                </form>

                <a href="#artists" className="bouton-rectangle-alliances">
                  Voir les artistes
                </a>
                <a href="#livret" className="bouton-rectangle-alliances">
                  Voir le livret
                </a>

              </div>
            </div>
          </Col>

        </Row>

      </div>

      <div className='music-spotify'>
        <h1> Écouter des extraits </h1>
        <div className='music-spotify-container'>
          <div className='text-center alliances-un'>
            <h5 className='music-titre'>Alliances CD1</h5>
            <div
              dangerouslySetInnerHTML={{ __html: iframeCode }}
            />
          </div>
          <div className='text-center'>
            <h5 className='music-titre'>Alliances CD2</h5>
            <div
              dangerouslySetInnerHTML={{ __html: iframeCodes }}
            />
          </div>

        </div>
      </div>

      <div className="music-container">

        <h3 className='music-titre'>Disponible sur toutes les plateformes musicales</h3>
        <div className="logo-streaming">
          <div className="center-content"> <a href='https://open.spotify.com/intl-fr/artist/75Xu6l5hFNMoVPPaVZ9ga1' target="_blank"><img src={spotify} className="logo-icon" /></a> </div>
          <div className="center-content"> <a href='https://www.deezer.com/fr/artist/1362957?deferredFl=1' target="_blank"> <img src={deezer} className="logo-icon" /></a> </div>
          <div className="center-content"> <a href='https://music.apple.com/fr/artist/rido-bayonne/423286493' target="_blank"> <img src={apple} className="logo-icon" /> </a></div>
          <div className="center-content"> <a href='https://music.amazon.com/artists/B004QLXGLY/rido-bayonne' target="_blank"><img src={amazon} className="logo-icon amazon-logo" /> </a></div>
          <div className="center-content"> <a href='https://www.youtube.com/channel/UCmn4T5ejhSre6UZa1dFy1fw' target="_blank"><img src={youtube} className="logo-icon" /></a> </div>
        </div>
      </div>

      <div id="artists">
        <Artists />
      </div>

      <div id="livret">
        <Livret />
      </div>
      <div className="container-alliances"></div>
      <footer className="footer">
        <div className="footerDiv">
          Copyright 2023 &copy;  Designed & Developed by&nbsp;<a href="https://www.maissadev.com" target="_blank">Maissa Dev</a>
        </div>
      </footer>



    </>
  );
}

