
import './App.css';
import { Alliances } from './Composants/Alliances';
import { NavBar } from './Composants/NavBar'

function App() {
  return (
    <div className="app-container">

      <NavBar />
      <Alliances />
      
    </div>
  );
}

export default App;
