import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Row, Col } from 'react-bootstrap';

import img1 from '../Assets/Livret/LIVRET1.jpg';
import img2 from '../Assets/Livret/LIVRET2.jpg';
import img3 from '../Assets/Livret/LIVRET3.jpg';
import img4 from '../Assets/Livret/LIVRET4.jpg';
import img5 from '../Assets/Livret/LIVRET5.jpg';
import img6 from '../Assets/Livret/LIVRET6.jpg';
import img7 from '../Assets/Livret/LIVRET7.jpg';
import img8 from '../Assets/Livret/LIVRET8.jpg';
import img9 from '../Assets/Livret/LIVRET9.jpg';
import img10 from '../Assets/Livret/LIVRET10.jpg';
import img11 from '../Assets/Livret/LIVRET11.jpg';
import img12 from '../Assets/Livret/LIVRET12.jpg';
import img13 from '../Assets/Livret/LIVRET13.jpg';
import img14 from '../Assets/Livret/LIVRET14.jpg';
import img15 from '../Assets/Livret/LIVRET15.jpg';
import img16 from '../Assets/Livret/LIVRET16.jpg';
import img17 from '../Assets/Livret/LIVRET17.jpg';
import img18 from '../Assets/Livret/LIVRET18.jpg';
import img19 from '../Assets/Livret/LIVRET19.jpg';
import img20 from '../Assets/Livret/LIVRET20.jpg';
import img21 from '../Assets/Livret/LIVRET21.jpg';
import img22 from '../Assets/Livret/LIVRET22.jpg';
import img23 from '../Assets/Livret/LIVRET23.jpg';
import img24 from '../Assets/Livret/LIVRET24.jpg';
import img25 from '../Assets/Livret/LIVRET25.jpg';
import img26 from '../Assets/Livret/LIVRET26.jpg';
import img27 from '../Assets/Livret/LIVRET27.jpg';
import img28 from '../Assets/Livret/LIVRET28.jpg';
import img29 from '../Assets/Livret/LIVRET29.jpg';
import img30 from '../Assets/Livret/LIVRET30.jpg';
import img31 from '../Assets/Livret/LIVRET31.jpg';


export const Livret = () => {

    return (
            <div className='livret-container'>
            <h2 className='artists-titre artists-titre-livret'>Le livret de l'album Alliances</h2>
                <div >

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img1} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img2} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img3} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img4} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img5} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img6} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img7} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img8} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img9} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img10} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img11} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img12} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img13} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img14} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img15} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img16} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img17} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img18} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img19} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img20} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img21} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img22} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img23} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img24} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img25} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img26} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img27} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img28} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img29} />
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                        <img src={img30} />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={6} lg={4}  className="d-flex align-items-center justify-content-center">
                        <img src={img31} />
                        </Col>
                    </Row>

                    
                </div>
            </div>
            );
}

export default Livret;


