import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '../Assets/logo.jpg';

import spotify from '../Assets/Alliances/spotify.png';
import apple from '../Assets/Alliances/apple.png';
import deezer from '../Assets/Alliances/deezer.png';
import youtube from '../Assets/Alliances/ytb.png';


export const NavBar = () => {
  

  return (

    <Navbar>

      <Navbar.Brand>
        <img
          src={logo}
          alt="Rido Bayonne Logo"
          className="logo-navbar"
        />
      </Navbar.Brand>
  </Navbar>
   
  )
}
